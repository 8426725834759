/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import * as ROUTES from "../constants/routes";
// @ts-ignore
import {openSpinner} from "@paytheory/pay-theory-ui"
import {
    MoveDirection,
    QueryPair,
    RecurringPayment,
    SortDirection,
    Transaction
} from "../GraphQL/backoffice";
import {PayTheoryColor} from "@paytheory/components.common.global_style";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "../settingsUtil";
import {AmplifyUser} from "@aws-amplify/ui";

const generateMenu = () => {
    return [{
        to: ROUTES.MANAGE_MERCHANTS,
        className: "active",
        tag: "manage-merchants",
        icon: "building",
        label: "Manage Merchants",
        isCategory: false
    },
        {
            to: ROUTES.MANAGE_ADMINS,
            className: "inactive",
            tag: "manage-administrators",
            icon: "users-cog",
            label: "Manage Administrators",
            isCategory: false
        },
        {
            to: ROUTES.SETTINGS,
            className: "inactive",
            tag: "settings",
            icon: "cog",
            label: "Settings",
            isCategory: false
        }
    ];
};

const prepRoute = (route: string, replacements: { key: string, value: any }[] = []) => {
    return replacements.reduce((prepped, item) => {
        const keyed = `:${item.key}`
        return prepped.replace(keyed, item.value)
    }, route);
};

export {
    generateMenu,
    prepRoute
};

type statusChipObject = {
    color: PayTheoryColor,
    textColor: PayTheoryColor,
    text: string
}

export const statusChip: { [key: string]: statusChipObject } = {
    settled: {
        color: "grey",
        textColor: "white",
        text: "Settled"
    },
    paid: {
        color: "grey",
        textColor: "white",
        text: "Paid"
    },
    active: {
        color: "mint",
        textColor: "black",
        text: "Active"
    },
    inactive: {
        color: "grey-2",
        textColor: "black",
        text: "Inactive"
    },
    overdue: {
        color: "raspberry",
        textColor: "white",
        text: "Overdue"
    },
    reversed: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    refunded: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    pending: {
        color: "grey-2",
        textColor: "black",
        text: "Pending"
    },
    succeeded: {
        color: "mint",
        textColor: "black",
        text: "Succeeded"
    },
    failed: {
        color: "raspberry",
        textColor: "white",
        text: "Failed"
    },
    partially_refunded: {
        color: "yellow",
        textColor: "black",
        text: "Partially Refunded"
    },
    partially_paid: {
        color: "yellow",
        textColor: "black",
        text: "Partially Paid"
    },
    expiring: {
        color: "yellow",
        textColor: "black",
        text: "Expiring Card"
    },
    expired: {
        color: "raspberry",
        textColor: "white",
        text: "Expired Card"
    },
    returned: {
        color: "yellow",
        textColor: "black",
        text: "Returned"
    },
    voided: {
        color: "yellow",
        textColor: "black",
        text: "Voided"
    }
};

export const formatFee = (value: number | null) => {
    const newValue = value?.toString().replace(/[^\d.-]/g, '');
    let amount = parseFloat(newValue ?? "") || 0;
    return amount !== 0 ? (amount / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00';
};

export const brandClasses = {
    VISA: "pay-theory-card-visa",
    DISCOVER: "pay-theory-card-discover",
    MASTERCARD: "pay-theory-card-mastercard",
    AMERICAN_EXPRESS: "pay-theory-card-american-express",
    AMEX: "pay-theory-card-american-express",
    CASH: "pay-theory-cash-badge",
    ACH: "pay-theory-ach-badge"
};

export const formatBasisPoints = (bp: number) => {
    const originalAmount = 100000;
    const totalAmount = Math.round(originalAmount / (1 - bp / 10000));
    const fee = totalAmount - originalAmount;
    return ((fee / originalAmount) * 100).toFixed(2);
};

export const formatDate = (date: string | Date) => {
    const dated = new Date(date);
    const month = dated.getMonth();
    const day = dated.getDate();
    const year = dated.getFullYear();
    const currentYear = new Date().getFullYear();

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[month]} ${day}${currentYear !== year ? `, ${year}` : ''}`;
};

export const formatFullDate = (stamp: string) => {
    const dated = new Date(stamp);
    const month = (dated.getMonth() + 1).toString().padStart(2, '0');
    const day = (dated.getDate()).toString().padStart(2, "0");
    const year = dated.getFullYear();
    const hour = (dated.getHours() % 12 || 12).toString().padStart(2, "0");
    const minute = (dated.getMinutes()).toString().padStart(2, "0");
    const amOrPm = dated.getHours() > 11 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${minute} ${amOrPm}`;
};

export const formatCurrency = (amount: number | string, currency = "USD") => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });
    amount = typeof amount === "string" ? parseFloat(amount) : amount;
    return formatter.format(amount);
}

function arrayToCSV(objArray: {}[]): string {
    if (objArray.length > 0) {
        const array =
            typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let str =
            `${Object.keys(array[0])
                .join(",")}` + "\r\n";

        return array.reduce((str: string, next: any) => {
            str +=
                `${Object.keys(next)
                    .map((key) => `"${next[key]}"`)
                    .join(",")}` + "\r\n";
            return str;
        }, str) + "\r\n\r\n";
    }
    return ""
}

/* global Blob URL */
export const downloadCSV = (items: { title?: string, items: {}[] }[], fileName: string) => {
    let link = document.createElement("a");

    // Avoid scrolling to bottom
    link.style.top = "0";
    link.style.left = "0";
    link.style.position = "fixed";

    document.body.appendChild(link);
    let text = "";
    for (const item of items) {
        if (item.items.length > 0) {
            if (item.title) text += `${item.title}\r\n`;
            text += arrayToCSV(item.items);
        }
    }
    const data = new Blob([text], {type: "text/csv"});
    link.href = URL.createObjectURL(data);
    link.download = `${fileName}.csv`;
    link.onclick = (e) => {
        if (items.length === 0) e.preventDefault();
    };
    link.click();

    document.body.removeChild(link);
};

type TransactionCSV = {
    merchant_uid: string;
    transaction_id: string;
    transaction_date: string;
    status: string;
    settlement_batch: string;
    payment_type: string;
    card_brand: string;
    last_four: string;
    full_name: string;
    reference: string;
    phone: string;
    email: string;
    account_code: string;
    transaction_type: string;
    dispute_status: string;
    net_amount: string;
    gross_amount: string;
    refunded_amount: string;
    fees: string;
    currency: string;
    failure_reasons: string;
    refund_reason: string;
    billing_address_line1: string;
    billing_address_line2: string;
    billing_city: string;
    billing_region: string;
    billing_postal_code: string;
    billing_country: string;
    payor_address_line1: string;
    payor_address_line2: string;
    payor_city: string;
    payor_region: string;
    payor_postal_code: string;
    payor_country: string;
}

export const prepareTransactionsForCSV = (transactions: Transaction[]): TransactionCSV[] => {
    return transactions.map((transaction) => {
        const result: TransactionCSV = {
            merchant_uid: transaction.merchant_uid!,
            transaction_id: transaction.transaction_id!,
            transaction_date: formatFullDate(transaction.transaction_date!),
            status: transaction.status!,
            settlement_batch: transaction.settlement_batch?.toString() ?? "",
            payment_type: transaction.payment_method!.payment_type!,
            net_amount: typeof transaction.net_amount === "number" ? formatCurrency(transaction.net_amount / 100, transaction.currency ?? "USD") : "",
            gross_amount: typeof transaction.gross_amount === "number" ? formatCurrency(transaction.gross_amount / 100, transaction.currency ?? "USD") : "",
            fees: typeof transaction.fees === "number" ? formatCurrency(transaction.fees / 100, transaction.currency ?? "USD") : "",
            refunded_amount: typeof transaction.refunded_amount === "number" ? formatCurrency(transaction.refunded_amount / 100, transaction.currency ?? "USD") : "",
            currency: transaction.currency ?? "",
            card_brand: transaction.payment_method!.card_brand ?? "",
            last_four: transaction.payment_method?.last_four ?? "",
            full_name: transaction.payment_method?.payor?.full_name ?? "",
            reference: transaction.reference ?? "",
            phone: transaction.payment_method?.payor?.phone ?? "",
            email: transaction.payment_method?.payor?.email ?? "",
            account_code: transaction.account_code ?? "",
            transaction_type: transaction.transaction_type ?? "",
            dispute_status: transaction.dispute_status ?? "",
            failure_reasons: transaction.failure_reasons?.join(" | ") ?? "",
            refund_reason: `${transaction.refund_reason?.reason_code ?? ""}${transaction.refund_reason?.reason_details ? ` | ${transaction.refund_reason?.reason_details}` : ""}`,
            billing_address_line1: transaction.payment_method?.address_line1 ?? "",
            billing_address_line2: transaction.payment_method?.address_line2 ?? "",
            billing_city: transaction.payment_method?.city ?? "",
            billing_region: transaction.payment_method?.region ?? "",
            billing_postal_code: transaction.payment_method?.postal_code ?? "",
            billing_country: transaction.payment_method?.country ?? "",
            payor_address_line1: transaction.payment_method?.payor?.address_line1 ?? "",
            payor_address_line2: transaction.payment_method?.payor?.address_line2 ?? "",
            payor_city: transaction.payment_method?.payor?.city ?? "",
            payor_region: transaction.payment_method?.payor?.region ?? "",
            payor_postal_code: transaction.payment_method?.payor?.postal_code ?? "",
            payor_country: transaction.payment_method?.payor?.country ?? "",
        }
        return result;
    })
}

export const findPaymentMethodLogo = (item: Transaction | RecurringPayment): keyof typeof brandClasses => {
    if (item.payment_method?.card_brand) {
        return item.payment_method.card_brand.toUpperCase() === "AMEX" ? "AMERICAN_EXPRESS" : item.payment_method.card_brand.toUpperCase() as keyof typeof brandClasses;
    }
    return item.payment_method?.card_brand as keyof typeof brandClasses;
};

export const compareState = (a: any, b: any) => {
    if (typeof a === 'object' && typeof b === 'object') {
        let result = false;
        Object.keys(a).forEach(key => {
            if (a[key] !== b[key]) {
                result = true;
            }
        });
        return result;
    } else {
        return a !== b;
    }
};

export const useDebounce = <T>(value: T, action: (value: T) => void, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                if (compareState(value, debouncedValue)) {
                    setDebouncedValue(value);
                    action(value)
                }
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
};

export const useUserTimeout = (user: AmplifyUser | null, signOut: () => void) => {
    useEffect(() => {
        // Set timeout longer for sandbox
        const minutesTillTimeout = process.env.REACT_APP_STAGE !== 'paytheory' ? 60 : 10;
        const timeout = minutesTillTimeout * 60000;
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown'];
        const username: string | undefined = user?.username

        // Function to update users timestamp in local storage
        let updateTimestamp = () => {
            const timestamp = Date.now()
            if (username) {
                setLocalStorage(user?.username!)(`${timestamp}`)
            }
        }

        // Function to check if user has been inactive for longer than timeout
        let checkTimestamp = () => {
            if (!username) return
            const timestamp = getLocalStorage(username)()
            const parsedTimestamp = timestamp ? parseInt(timestamp) : 0
            if (!parsedTimestamp) return
            const now = Date.now()
            const diff = now - parsedTimestamp
            if (diff > timeout) {
                signOut()
                // After 20 seconds, remove timestamp from local storage
                setTimeout(() => {
                    removeLocalStorage(username)()
                }, 20000)
            }

        }

        // If no timestamp in local storage, set one
        if (username) {
            const timestamp = getLocalStorage(username)()
            if (!timestamp) {
                updateTimestamp()
            }
        }

        // Set interval to check timestamp
        let t = setInterval(checkTimestamp, 5000);

        actions.forEach((action) => {
            document.addEventListener(action, updateTimestamp, {
                capture: false,
                passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimestamp)
            })
            clearInterval(t)
        };
    }, [user, signOut]);
}

export const validDate = (date: string) => {
    return date.match(/^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])\/[0-9]{4}$/) ? true : false;
};

export const validEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? true : false;
};



const addMonths = (date: Date, months: number) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + +days);
    return date;
};

const addYears = (date: Date, years: number) => {
    var d = date.getDate();
    date.setFullYear(date.getFullYear() + +years);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
};

let dateOffsetValues: {
    [key: string]: {
        function: (date: Date, value: number) => Date,
        value: number
    }
} = {
    '1D': {
        function: addDays,
        value: 1
    },
    '1W': {
        function: addDays,
        value: 7
    },
    '1M': {
        function: addMonths,
        value: 1
    },
    '3M': {
        function: addMonths,
        value: 3
    },
    '1Y': {
        function: addYears,
        value: 1
    }
};

export const formatDateToISO = (value: string, endOfDay: boolean) => {
    let date = new Date(value).toISOString()
    let dateString = date.split('T')[0]
    let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
    return dateString + ending;
};


export const findDateOffset = (value: string, endOfDay: boolean) => {
    let valueObject = dateOffsetValues[value];
    let date = valueObject.function(new Date(), -valueObject.value);
    let dateString = date.toISOString().split('T')[0];
    let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
    return dateString + ending;
};

export const capitalize = (string: string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertAmount = (amount: number, currency: string = "USD") => {
    const fraction = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });
    const whole = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const adjustedAmount = amount / 100;
    return adjustedAmount % 1 === 0 ? whole.format(adjustedAmount) : fraction.format(adjustedAmount);
};

export const resultsPerPageOptions = [
    {
        value: 10,
        label: '10'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 100,
        label: '100'
    }
];

export type paginationAction = 'FIRST' | 'BACK' | 'FORWARD' | 'LAST';
export type paginationFunctionType<T, F extends {
    [keys: string | number]: any
} | string | QueryPair | QueryPair[]> = (message: string | null,
                                         order: SortDirection,
                                         offset: T | null,
                                         limit: number,
                                         filter: F,
                                         direction: MoveDirection,
                                         flip: boolean) => any;

export const onPagination = <T, F extends {
    [keys: string | number]: any
} | string | QueryPair | QueryPair[]>(props: {
    func: paginationFunctionType<T, F>,
    limit: number,
    filter: F,
    totalPages: number,
    totalResults: number,
    resultsArray: T[],
    page: number,
    setPage: Dispatch<SetStateAction<any>>
    defaultSort?: SortDirection,
}) => (action: paginationAction) => {
    const {func, limit, filter, totalPages, totalResults, resultsArray, page, setPage, defaultSort = SortDirection.Desc} = props;
    const reverseSort = defaultSort === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
    openSpinner();
    let newPage = 0;
    // Calculate new page
    switch (action) {
        case 'FIRST':
            newPage = 1
            break;
        case 'BACK':
            newPage = page - 1
            break;
        case 'FORWARD':
            newPage = page + 1
            break;
        case 'LAST':
            newPage = totalPages
            break;
        default:
            newPage = page
    }
    setPage(newPage)
    //Perform the action based on the new page
    if (newPage === 1) {
        func(null, defaultSort, null, limit, filter, MoveDirection.Forward, false)
    } else if (newPage === totalPages) {
        let newLimit = totalResults % limit
        newLimit = newLimit === 0 ? limit : newLimit
        func(null, reverseSort, null, newLimit, filter, MoveDirection.Forward, true)
    } else if (action === 'FORWARD') {
        const offset = resultsArray.slice(-1)[0]
        func(null, defaultSort, offset, limit, filter, MoveDirection.Forward, false)
    } else if (action === 'BACK') {
        const offset = resultsArray[0]
        func(null, defaultSort, offset, limit, filter, MoveDirection.Backward, false)
    }
};
export const convertEmptyStringsToNull = (inputObject: object): object => {
    const copy = JSON.parse(JSON.stringify(inputObject))
    Object.keys(copy).forEach((key) => {
        if (copy[key] === "") {
            copy[key] = null;
        } else if (copy[key] instanceof Object) {
            copy[key] = convertEmptyStringsToNull(copy[key]);
        }
    })
    return copy;
}

export type MenuItemType = {
    menu: string,
    tab: string
}

export const selectTab = (selected: MenuItemType) => {
    const menu = document.getElementById(selected.menu)
    const tab = document.getElementById(selected.tab)

    tab?.classList.remove('gone')
    tab?.classList.add('tab-visible')
    menu?.classList.add('active-tab')
}

export const clearUnselected = (unselected: Array<MenuItemType>) => {
    unselected.forEach(item => {
        const menu = document.getElementById(item.menu)
        const tab = document.getElementById(item.tab)
        tab?.classList.remove('tab-visible')
        tab?.classList.add('gone')
        menu?.classList.remove('active-tab')
    })
}

export const parseSocketError = (error: any) => {
    // Return an empty string if the payload is empty.
    if (error.length === 0) return "";
    // Else return the first message in the payload
    return error[0].message;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const removeCountryCode = cleaned.substring(cleaned.length - 10);
    const match = removeCountryCode.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
};