//authentication
export const LANDING = '/';
export const ACCOUNT = '/account';

//administration
export const MANAGE_ADMINS = '/manage-administrators';
export const ADMIN_ACCOUNT_DETAIL =
  '/manage-administrators/account-detail/:key';

//merchant
export const MANAGE_MERCHANTS = '/manage-merchants';
export const HOME = MANAGE_MERCHANTS;
export const MERCHANT_DETAIL = '/manage-merchants/merchant-detail/:key';

//payment-parameters
export const PAYMENT_PARAMETERS = '/payment-parameters';
export const PAYMENT_PARAMETERS_DETAILS = '/payment-parameters/details';

//error
export const VERIFICATION_FAILED = '/verification-failed';
export const NOT_FOUND = '/not-found';
export const UNAUTHORIZED = '/unauthorized';

// settings
export const SETTINGS = '/settings';
export const WEBHOOK_DETAIL = '/settings/webhook-detail/:endpoint';
export const WEBHOOK_HISTORY_DETAIL =
  '/settings/webhook-detail/:endpoint/history/:eventId';
